import React from "react";
import TypeWriter from "react-rotating-text";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GitHub from "@material-ui/icons/GitHub";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Twitter from "@material-ui/icons/Twitter";
import Email from "@material-ui/icons/EmailRounded";
import Resume from "@material-ui/icons/DescriptionOutlined";
import MainBackground from "./images/mainBackground.png";
import OfficialResume from "./documents/officialResume.pdf";

const width = window.innerWidth; // Holds the width of the screen
const height = window.innerHeight; // Holds the height of the screen
const mobileLimit = 2000; // Holds the screen size limit to be considered mobile

// Words that can be used to describe me.
const descriptions = [
  "Student",
  "Developer",
  "Freelancer",
  "Professional",
  "Avid Learner",
];

/**
 * Style to hold the information for the main page
 * This will hold all styling for components used and how
 * they are structured.
 */
const mainStyles = {
  root: {
    background: `url(${MainBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: height,
    minWidth: width,
    width: width,
    height: height,
    position: "absolute",
    filter: "blur(6px)",
    WebkitFilter: "blur(7px)",
    top: 0,
    left: 0,
    zIndex: -99,
  },
  texts: {
    h1: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "2px",
      fontSize: "4em",
    },
    typewriter: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "1.2px",
      fontSize: "2.2em",
    },
    mainParagraph: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "1.2px",
      lineHeight: "1.6em",
      fontSize: "1.3em",
    },
    h1Mobile: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "2px",
      fontSize: "2.2em",
    },
    typewriterMobile: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "1.2px",
      fontSize: "1.7em",
    },
    mainParagraphMobile: {
      color: "#ffffff",
      fontFamily: "Optima",
      fontWeight: "normal",
      letterSpacing: "1.2px",
      lineHeight: "1.6em",
      fontSize: "0.9em",
    },
  },
  containers: {
    horizontalAlign: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: height,
    },
    verticalAlign: {
      verticalAlign: "middle",
    },
    informationContainer: {
      display: "inline-block",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      width: (22 * width) / 100,
      marginLeft: (5 * width) / 100,
      marginRight: (5 * width) / 100,
    },
    informationContainerMobile: {
      display: "inline-block",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      width: (90 * width) / 100,
      marginLeft: (5 * width) / 100,
      marginRight: (5 * width) / 100,
    },
  },
  icons: {
    contact: {
      fontSize: "1.5em",
      color: "#ffffff",
    },
  },
  randoms: {
    typewriter: {
      marginTop: (-2.9 * height) / 100,
    },
  },
};

/**
 * Main application view.
 * This view currently renders all part of the app.
 */
function App() {
  // Handler for the click actions on the screen.
  // It will check which button was clicked and decide
  // where the user should be redirected to appropriately.
  const handleClick = (event) => {
    if (event.currentTarget.value === "github") {
      window.open("https://github.com/emmanuelogunjirin");
    } else if (event.currentTarget.value === "linkedin") {
      window.open("https://www.linkedin.com/in/emmanuel-ogunjirin");
    } else if (event.currentTarget.value === "twitter") {
      window.open("https://twitter.com/I_Ogunjirin10");
    } else if (event.currentTarget.value === "resume") {
      window.open(OfficialResume);
    }
  };

  console.log(width);
  return (
    <>
      <div style={mainStyles.root}> Hello </div>
      <div style={mainStyles.containers.horizontalAlign}>
        <div
          style={
            width < mobileLimit
              ? mainStyles.containers.informationContainerMobile
              : mainStyles.containers.informationContainer
          }
        >
          <div style={mainStyles.containers.verticalAlign}>
            <h1
              style={
                width < mobileLimit
                  ? mainStyles.texts.h1Mobile
                  : mainStyles.texts.h1
              }
            >
              Hi! I'm Emmanuel.
            </h1>
            <div style={mainStyles.randoms.typewriter}>
              <TypeWriter
                style={
                  width < mobileLimit
                    ? mainStyles.texts.typewriterMobile
                    : mainStyles.texts.typewriter
                }
                items={descriptions}
                pause={2000}
                emptyPause={1000}
                typingInterval={50}
                deletingInterval={100}
              />
            </div>
            <p
              style={
                width < mobileLimit
                  ? mainStyles.texts.mainParagraphMobile
                  : mainStyles.texts.mainParagraph
              }
            >
              I'm currently studying Computer Engineering at the University of
              Virginia. I have experience in software engineering environment
              and roles. As a fullstack web developer, I am mostly using React
              and Node.JS. However, I am a fast learner, and I am open to
              adopting new technologies to expand my skill set.
            </p>
            <p
              style={
                width < mobileLimit
                  ? mainStyles.texts.mainParagraphMobile
                  : mainStyles.texts.mainParagraph
              }
            >
              Please, feel free to reach out through any of the links below. I
              would love to get in touch! Thanks!!
            </p>
            <div>
              <Tooltip title="Github" arrow>
                <IconButton
                  value="github"
                  onClick={(event) => handleClick(event)}
                >
                  <GitHub style={mainStyles.icons.contact} />
                </IconButton>
              </Tooltip>
              <Tooltip title="LinkedIn" arrow>
                <IconButton
                  value="linkedin"
                  onClick={(event) => handleClick(event)}
                >
                  <LinkedIn style={mainStyles.icons.contact} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Resume" arrow>
                <IconButton
                  value="resume"
                  onClick={(event) => handleClick(event)}
                >
                  <Resume style={mainStyles.icons.contact} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Email" arrow>
                <IconButton
                  value="email"
                  href="mailto:emmanuel@ogunjirin.me?subject=Hello!"
                >
                  <Email style={mainStyles.icons.contact} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Twitter" arrow>
                <IconButton
                  value="twitter"
                  onClick={(event) => handleClick(event)}
                >
                  <Twitter style={mainStyles.icons.contact} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App; // Exports a default function to be rendered by the file
