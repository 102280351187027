import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

/**
 * Item to render the items on the screen.
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root") // Tags the root of the page
);
